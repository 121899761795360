import { useState, useEffect } from 'react';
import _, { isEqual } from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import companyService from '../../service/company.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import useMenus from '../../context/MenuContext';
import useParam from '../../context/ParamContext';
import useUsers from '../../context/UsersContext';
import { getDynVals } from '../../utils/DynHelper';

import FormTabs from './FormTabs';
import CompanyReports from './CompanyReports';
import FormFields from './FormFields';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, FormBase } from '../Interface';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';

export const CompanyPage = () => {
  const { user, getUser } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [dynProps, setDynProps] = useState([]);

  const [submenu, setSubmenu] = useState(null);
  const [refetchTable, setRefetchTable] = useState(false);
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    status: [],
  });
  const [loading, setLoading] = useState(true);
  const [dynLoading, setDynLoading] = useState(true);
  const isLoading = loading || dynLoading;

  const defaultContact = {
    id: null,
    isNew: true,
    name: '',
    role: '',
    phoneNumber: '',
    email: '',
    createdBy: user.userId,
  };

  const def = {
    companyName: '',
    billPostcode: '',
    billCity: '',
    billAddress: '',
    postCode: '',
    city: '',
    street: '',
    companyDescription: '',
    statusId: null,
    contact: [],
  };

  useEffect(() => {
    getMenus();
    getParam();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('company');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    masterDataService
      .statusToForm(submenu)
      .then((data) => {
        const aData = {
          status: data,
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
        };
        setAutoCompleteOptions(acOptions);
        setLoading(false);
      })
      .finally(() => {});
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(),
    billPostcode: Yup.number().nullable().positive(),
    billCity: Yup.string().nullable(),
    billAddress: Yup.string().nullable(),
    postCode: Yup.number().nullable().nullable(),
    city: Yup.string().nullable(),
    street: Yup.string().nullable(),
    companyDescription: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    contact: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        role: Yup.string().required(),
        phoneNumber: Yup.string().nullable(),
        email: Yup.string().nullable(),
      })
    ),
  });

  const valuesToForm = () => {
    const {
      companyId,
      companyName,
      billPostcode,
      billCity,
      billAddress,
      postCode,
      city,
      street,
      companyDescription,
      statusId,
      companyContact,
    } = selectedValues;

    setInitValues({
      companyId,
      companyName,
      billPostcode,
      billCity,
      billAddress,
      postCode,
      city,
      street,
      companyDescription,
      statusId,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
      contact:
        companyContact &&
        companyContact.map((pc) => ({
          id: pc.id,
          name: pc.name,
          role: pc.role,
          phoneNumber: pc.phoneNumber,
          email: pc.email,
        })),
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    companyService.updateCompany(updData, selectedValues.companyId).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    companyService
      .createCompany(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);

        setRowSelectionModel(data.companyId);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    companyService.deleteCompany(deleteData, selectedValues.companyId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  const handleTemplate = () => {
    companyService.getExcelTemplate().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleExport = () => {
    companyService.getExcelData().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleImport = (files) => {
    companyService.importExcel(files, user.userId).then(() => {
      setRefetchTable(true);
      addNotification({
        content: 'Az import sikeres!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  return (
    <>
      <FormBase
        name="Company"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
                //validateOnMount={false}
              >
                {({ values, errors, resetForm, setFieldValue, validateField }) => (
                  <Form>
                    <FormFields
                      values={values}
                      errors={errors}
                      viewField={viewField}
                      params={params}
                      setFieldValue={setFieldValue}
                      validateField={validateField}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                    />

                    <FormTabs
                      values={values}
                      errors={errors}
                      disabled={viewField}
                      setFieldValue={setFieldValue}
                      selectedValues={selectedValues}
                      validateField={validateField}
                      submenuId={submenu}
                      params={params}
                      user={user}
                      setRefetchTable={setRefetchTable}
                      setDefaultValues={setDefaultValues}
                      dynProperties={{ dynProps, setDynProps }}
                      defaultContact={defaultContact}
                      dynLoadingState={{ dynLoading, setDynLoading }}
                      setSelectedValues={setSelectedValues}
                      handleImport={handleImport}
                      handleExport={handleExport}
                      handleTemplate={handleTemplate}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            {!isLoading && (
              <CompanyReports
                refetch={{ refetchTable, setRefetchTable }}
                values={{ selectedValues, setSelectedValues }}
                viewField={viewField}
                operatorsDisabled={operatorsDisabled}
                rowSelection={{ rowSelectionModel, setRowSelectionModel }}
                dynProps={dynProps}
                autocompleteOptions={autocompleteOptions}
              />
            )}
          </>
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
