import { DialogUI, ButtonUI } from '.';

export default function ConfirmDialogUI({
  handleChangeElement,
  handleOpen,
  show,
  onHide,
  message,
  title,
  showConfirmButton = true,
}) {
  return (
    <DialogUI
      open={show}
      handleOpen={handleOpen}
      onClose={onHide}
      headerContent={title ?? 'Művelet megerősítése'}
      fullWidth={true}
    >
      <div className="relative">
        <div className="mt-2 flex gap-10 items-center justify-center z-50">
          <div className="relative">
            <div className="relative mt-5">
              <p className="text-2xl text-labelColor">{message ?? `Megerősíti a műveletet?`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 mt-8 flex justify-center gap-3">
        {showConfirmButton && (
          <ButtonUI text="Megerősítés" className="bg-success" onClick={handleChangeElement}></ButtonUI>
        )}
        <ButtonUI text="Mégse" className="" color="blue-gray" variant="gradient" onClick={onHide}></ButtonUI>
      </div>
    </DialogUI>
  );
}
