import React from 'react';
import { Field } from 'formik';
import { FieldType, PropertyType } from '../../config/property';

import { InputUI, FormErrorUI, FormLabelUI, CheckboxUI, SelectUI } from '../Interface';

const FormFields = ({ viewField, values, validateField, setFieldValue, errors, selectedValues }) => {
  // @TODO ki kell próbálni !!selectedValues.defaultValue
  const hasDefVal = selectedValues.defaultValue !== undefined && selectedValues.defaultValue !== null;
  return (
    <div className="grid grid-cols-6 gap-x-4 gap-y-2 mx-4 items-center mb-3">
      <div className="col-span-6 md:col-span-3">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Megnevezés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>
      <div className="col-span-6 md:col-span-2">
        <Field
          name="type"
          component={SelectUI}
          fullWidth
          option={FieldType}
          value={values.type ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('type', value ?? null).then(() => {
              validateField('type');
              if (values.characterLength !== '') {
                setFieldValue('characterLength', '');
              }
            });
          }}
          label={<FormLabelUI text="Típus" />}
          helperText={<FormErrorUI message={errors.type} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.type !== '' }}
        />
      </div>
      <div className="col-span-6 md:col-span-1">
        <Field
          type="number"
          name="visOrder"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Sorrend" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.visOrder} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.visOrder !== '' }}
        />
      </div>
      <div className={`col-span-6 md:col-span-1 ${values.type !== PropertyType.LONGTEXT && 'hidden'}`}>
        <Field
          type="number"
          name="characterLength"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Karakterhossz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.characterLength} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.characterLength !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="mandatory"
          component={CheckboxUI}
          fullWidth
          checked={values?.mandatory === true || hasDefVal}
          label={<FormLabelUI text="Kötelező" />}
          onChange={(e) => {
            setFieldValue('mandatory', e.target.checked).then(() => {
              validateField('mandatory');
            });
          }}
          variant="standard"
          helperText={<FormErrorUI message={errors.mandatory} />}
          disabled={viewField || hasDefVal}
          InputLabelProps={{ shrink: values.mandatory !== '' }}
        />
      </div>
      <div className="col-span-6 md:col-span-4 flex gap-5 items-center">
        <div className={`${values.type !== PropertyType.DATE && 'hidden'}`}>
          <Field
            name="expiryMonitoring"
            component={CheckboxUI}
            checked={values?.expiryMonitoring === true || hasDefVal}
            label={<FormLabelUI text="Lejárat figyelés" />}
            onChange={(e) => {
              setFieldValue('expiryMonitoring', e.target.checked).then(() => {
                validateField('expiryMonitoring');
              });
            }}
            variant="standard"
            helperText={<FormErrorUI message={errors.expiryMonitoring} />}
            disabled={viewField || hasDefVal}
          />
        </div>
        <div className={`${(values.type !== PropertyType.DATE || !values.expiryMonitoring) && 'hidden'}`}>
          <Field
            name="emailNotification"
            component={CheckboxUI}
            checked={values?.emailNotification === true || hasDefVal}
            label={<FormLabelUI text="Email értesítés" />}
            onChange={(e) => {
              setFieldValue('emailNotification', e.target.checked).then(() => {
                validateField('emailNotification');
              });
            }}
            variant="standard"
            helperText={<FormErrorUI message={errors.emailNotification} />}
            disabled={viewField || hasDefVal}
          />
        </div>
        <div className={`${(values.type !== PropertyType.DATE || !values.expiryMonitoring) && 'hidden'}`}>
          <Field
            type="number"
            name="expiryAlertDays"
            as={InputUI}
            fullWidth
            label={<FormLabelUI text="Jelzés ennyivel előtte (nap)" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.expiryAlertDays} />}
            disabled={viewField}
            InputLabelProps={{ shrink: values.expiryAlertDays !== '' }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormFields;
