import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'expiryMonitoring';

const expiryMonitoringService = {
  getExpiringFields: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/expiringFields`,
      data,
    });
  },
};

export default expiryMonitoringService;
