import { useState } from 'react';
import { Field } from 'formik';

import { TabsUI, FormLabelUI, FormErrorUI, InputUI } from '../Interface';
import DynamicPropertyMain from '../DynamicProperty/DynamicPropertyMain';
import CompanyContact from './CompanyContact';
import CompanyHistory from './CompanyHistory';
import ExcelImportTab from '../Interface/ExcelImportTab';

const DescriptionPanel = ({ disabled, errors, values }) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="description"
            as={InputUI}
            className={`${disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Megjegyzés" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.companyDescription} />}
            disabled={disabled}
            InputLabelProps={{ shrink: values.companyDescription !== '' }}
            multiline
            rows={10}
          />
        </div>
      </div>
    </div>
  );
};

export default function FormTabs(props) {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Leírás',
      content: <DescriptionPanel {...props} />,
    },
    {
      id: 1,
      name: 'Tulajdonságok',
      content: <DynamicPropertyMain {...props} />,
    },
    {
      id: 2,
      name: 'Kapcsolattartók',
      content: <CompanyContact {...props} />,
    },
    {
      id: 3,
      name: 'Cég történet',
      content: <CompanyHistory {...props} />,
    },
    {
      id: 3,
      name: 'Adat import',
      content: <ExcelImportTab {...props} />,
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
}
