import React, { useState, useEffect } from 'react';
import useUsers from '../../context/UsersContext';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, ButtonUI } from '../Interface';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Formik, Form } from 'formik';

import crmTaskService from '../../service/crmTask.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import CrmTaskTabs from './CrmTaskTabs';
import CrmTaskFields from './CrmTaskFields';
import { getDynVals } from '../../utils/DynHelper';
import { useNavigate, useParams } from 'react-router-dom';

export const CrmTaskPage = ({ crmTaskState, dialog }) => {
  const {
    setRefetchTable,
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    canEdit,
    setRowSelectionModel,
    autoCompleteOptions,
    autocompleteData,
    dynProps,
    setDynProps,
    dynLoading,
    setDynLoading,
    submenuNames,
    setPages,
    loading,
  } = crmTaskState;
  const navigate = useNavigate();
  const { id } = useParams();
  const { getUser, user } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [defaultValues, setDefaultValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  const def = {
    name: '',
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()).add(1, 'hour'),
    duration: 1,
    typeId: null,
    projectId: null,
    companyId: null,
    responsiblePersonId: null,
    statusId: null,
    description: '',
    prefixedId: '',
    parentName: '',
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0 && dialog?.parentData) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          typeId: autocompleteData?.type?.defaultValue ?? null,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          ...(dialog &&
            !dialog?.isUpdate && {
              projectId: dialog?.parentData?.projectId,
              companyId: dialog?.parentData?.companyId,
              responsiblePersonId: dialog?.parentData?.responsiblePersonId,
              parentName: dialog?.parentData?.name,
            }),
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData, dialog?.parentData]);

  useEffect(() => {
    if (!id) {
      setSelectedValues(defaultSelectedValues);
    }
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (user) {
      setDefaultValues((prev) => {
        return { ...prev, createdBy: user?.userId };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          user: user,
        };
      });
    }
  }, [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    duration: Yup.number().positive(),
    typeId: Yup.string().nullable(),
    companyId: Yup.string().required(),
    projectId: Yup.string().nullable(),
    responsiblePersonId: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    description: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField || !dialog.isUpdate) {
      handleInsertObject(values);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    if (id && !loading) {
      getCrmTaskById();
    }
  }, [id, loading]);

  const getCrmTaskById = () => {
    crmTaskService.getTaskById(id).then((data) => {
      setSelectedValues(data);
      setRowSelectionModel(data.id);
    });
  };

  const valuesToForm = () => {
    const {
      name,
      startDate,
      endDate,
      duration,
      typeId,
      responsiblePersonId,
      createdBy,
      statusId,
      projectId,
      companyId,
      description,
      parentName,
      prefixedId,
    } = selectedValues;

    setInitValues({
      name,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      duration,
      typeId,
      projectId,
      companyId,
      responsiblePersonId,
      statusId,
      createdBy,
      description,
      parentName: parentName ?? defaultValues.parentName,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
      prefixedId: prefixedId ?? defaultValues.prefixedId,
    });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      duration: parseFloat(values.duration),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      updatedBy: user.userId,
    };

    crmTaskService.updateTask(updData, selectedValues.id).then((_crmTask) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);

      if (dialog) {
        dialog.setDataChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      duration: parseFloat(values.duration),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      createdBy: user.userId,
      ...(dialog && {
        parentId: dialog.parentId,
        dynamicFields: values.dynamicFields ?? defaultValues.dynamicFields,
      }),
    };

    crmTaskService
      .createTask(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel(data.id);

        if (dialog) {
          dialog.setDataChanged(true);
          dialog.handleClose();
        }
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    crmTaskService.deleteTask(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);

      if (dialog) {
        dialog.setDataChanged(true);
        dialog.handleClose();
      }
    });
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    if (selectedValues.deletable) {
    } else {
      setShowDeleteDialog(true);
    }
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  const handleNavigate = () => {
    setPages({ subMenuName: submenuNames });
    navigate(`/app/contactMap/${selectedValues.id}`);
  };

  return (
    <>
      <div className="h-full overflow-auto custom-scrollbar">
        <div className="flex items-center flex-wrap">
          <FormHeaderUI
            addClick={(!dialog || !dialog.isViewMode) && (!dialog ? onAddClick : null)}
            addDisabled={dialog?.isViewMode || !viewField || !canEdit}
            modClick={!dialog || dialog.isUpdate ? onModifyClick : null}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            //deleteClick={!dialog || dialog.isUpdate ? onDeleteClick : null}
            //deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={!dialog || dialog.isUpdate ? onCopyClick : null}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />
          {(!dialog || (dialog && dialog.isUpdate)) && (
            <ButtonUI
              text="Kapcsolattérkép"
              size="sm"
              className="bg-labelColor py-3"
              onClick={handleNavigate}
              disabled={!viewField || operatorsDisabled}
            />
          )}
        </div>

        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
          //validateOnMount={false}
        >
          {({ values, setFieldValue, errors, validateField, resetForm }) => (
            <Form>
              <CrmTaskFields
                submenuId={submenu}
                selectedValues={selectedValues}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                validateField={validateField}
                viewField={(!dialog || dialog?.isUpdate) && viewField}
                autoCompleteOptions={autoCompleteOptions}
                autocompleteData={autocompleteData}
              />

              <CrmTaskTabs
                values={values}
                errors={errors}
                submenuId={submenu}
                dynProperties={{ dynProps, setDynProps }}
                disabled={(!dialog || dialog?.isUpdate) && viewField}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setDefaultValues={setDefaultValues}
                setRefetchTable={setRefetchTable}
                user={user}
                dynLoadingState={{ dynLoading, setDynLoading }}
              />

              <FormButtonPanelUI
                onCancelClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setAddField(true);
                  resetForm();
                  if (dialog) {
                    dialog.handleClose();
                  }
                }}
                disabled={
                  dialog
                    ? dialog.isViewMode ||
                      (dialog.isUpdate && ((editButtonVisible && addField) || (!editButtonVisible && !addField)))
                    : (editButtonVisible && addField) || (!editButtonVisible && !addField)
                }
              />
            </Form>
          )}
        </Formik>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
